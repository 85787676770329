import { Drawer } from '@material-ui/core';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import { useAgencyConstants, useConfig } from '@core/contexts/ConfigContext';
import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button';
import { DrawerHeader } from '@shared/components/drawer-header';
import { FilterSelect } from '@shared/components/filter-select';
import { TextField } from '@shared/components/text-field';
import { drawerTransition } from '@shared/constants/drawer';
import { getQueryValueString, getQueryValueStringArray, getSocietiesState } from '@shared/utils/common';
import {
  objectToOptions,
  societiesToSelectOptions,
  societyCompaniesToSelectOptions,
  societyRegionsToOptions,
} from '@shared/utils/options';

import { styles } from './MarketplaceMembersFilterDrawer.styles';
import { FilteringInputState, FilteringSelectorState } from './MarketplaceMembersFilterDrawer.types';
import { compileFilterDataFromStates } from './MarketplaceMembersFilterDrawer.utils';
import { MarketplaceMembersFilteringParams } from '../../MarketplaceMembersIndex';

export interface MarketplaceMembersFilterDrawerProps extends WithStyles<typeof styles> {
  open: boolean;
  params?: MarketplaceMembersFilteringParams;
  onClear: () => void;
  onClose: () => void;
  onSave: (params: MarketplaceMembersFilteringParams) => void;
}

const MarketplaceMembersFilterDrawerComponent: FC<MarketplaceMembersFilterDrawerProps> = ({
  classes,
  open,
  params,
  onClear,
  onClose,
  onSave,
}) => {
  const { societyCompanies, user } = useConfig();
  const { constants } = useAgencyConstants();
  const { isOASMember, isIASMember } = getSocietiesState(user);

  const [selectState, setSelectState] = useState<FilteringSelectorState>({
    companies: [],
    regions: [],
    societies: [],
    committee_regions: [],
  });

  const [inputState, setInputState] = useState<FilteringInputState>({
    name: '',
    email: '',
  });

  useEffect(() => {
    if (!open) {
      return;
    }

    setSelectState({
      companies: getQueryValueStringArray('companies', params),
      regions: getQueryValueStringArray('regions', params),
      societies: getQueryValueStringArray('societies', params),
      committee_regions: getQueryValueStringArray('committee_regions', params),
    });
    setInputState({
      name: getQueryValueString('name', params),
      email: getQueryValueString('email', params),
    });
  }, [open, params]);

  const companiesTypeOptions = useMemo(() => societyCompaniesToSelectOptions(societyCompanies), [societyCompanies]);

  const societyRegionOptions = useMemo(
    () => societyRegionsToOptions(constants.societyRegions),
    [constants.societyRegions]
  );

  const societiesOptions = useMemo(() => societiesToSelectOptions(user.societies), [user.societies]);

  const societyCommitteeRegionOptions = useMemo(
    () => objectToOptions(constants.societyCommitteeRegionOptions),
    [constants.societyCommitteeRegionOptions]
  );

  const handleSelectChange = (type: string) => (value: Array<Id>) => {
    setSelectState((_prevData) => ({ ..._prevData, [type]: value }));
  };

  const handleInputChange = (type: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setInputState((_prevData) => ({ ..._prevData, [type]: e.target.value }));
  };

  const handleApplyFilter = () => {
    const params = compileFilterDataFromStates(selectState, inputState);

    onSave(params);
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      transitionDuration={drawerTransition}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.root}>
        <DrawerHeader header={t('filter_members')} onClose={onClose} classes={{ header: classes.header }} />
        <div className={classes.content}>
          <TextField
            label={t('name')}
            fullWidth
            value={inputState.name}
            onChange={handleInputChange('name')}
            placeholder="Enter Name"
            classes={{ root: classes.filter }}
          />
          <FilterSelect
            label={t('company')}
            value={selectState.companies}
            options={companiesTypeOptions}
            onChange={handleSelectChange('companies')}
            classes={{ root: classes.filter }}
          />
          <TextField
            label={t('email')}
            fullWidth
            value={inputState.email}
            onChange={handleInputChange('email')}
            placeholder="Enter Email"
            classes={{ root: classes.filter }}
          />
          <FilterSelect
            label={t('society')}
            value={selectState.societies}
            options={societiesOptions}
            onChange={handleSelectChange('societies')}
            classes={{ root: classes.filter }}
          />
          {isOASMember && (
            <FilterSelect
              label={t('region')}
              value={selectState.regions}
              options={societyRegionOptions}
              onChange={handleSelectChange('regions')}
              classes={{ root: classes.filter }}
            />
          )}
          {isIASMember && (
            <FilterSelect
              label={'IAS Committee Members'}
              value={selectState.committee_regions}
              options={societyCommitteeRegionOptions}
              onChange={handleSelectChange('committee_regions')}
              classes={{ root: classes.filter }}
            />
          )}
        </div>
        <div className={classes.footer}>
          <Button text={t('clear_filters_1')} color="default" variant="outlined" onClick={onClear} />
          <Button text={t('apply_filters_1')} onClick={handleApplyFilter} />
        </div>
      </div>
    </Drawer>
  );
};

export const MarketplaceMembersFilterDrawer = withStyles(styles)(MarketplaceMembersFilterDrawerComponent);
